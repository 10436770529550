<template>
  <div class="card">
    <div class="card-body" v-if="!onLoadEntity">
      <label v-if="this.$route.params.id && !onEdit" class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Template Name')}}</label>
      <div class="w-100 tw-flex mb-1">
        <div v-if="this.$route.params.id && !onEdit" style="min-width: fit-content">
          <label class="tw-flex tw-items-center tw-text-xl">{{template.contractTemplate.name}}</label>
        </div>
        <div class="w-100 tw-justify-end">
          <div class="tw-flex" v-if="!this.$route.params.id">
            <b-button class="btn btn-primary" @click="saveContractTemplate" :disabled="loading">
                <span class="align-middle">{{ $t('Save') }}</span>
            </b-button>
            <b-button variant="outline-primary" @click="cancelNewContract"  style="margin-left: 7px">
                <span class="align-middle">{{ $t('Cancel') }}</span>
            </b-button>
          </div>
          <div class="tw-flex tw-justify-end" v-else>
            <b-button class="btn btn-primary" v-if="!onEdit" @click="editContract">
                <span class="align-middle">{{ $t('Edit')}}</span>
            </b-button>
            <b-button class="btn btn-primary" v-if="onEdit" @click="saveEdit">
                <span class="align-middle">{{ $t('Save')}}</span>
            </b-button>

            <b-button style="margin-left: 7px" variant="outline-primary" @click="cancelEdit" v-if="onEdit">
                <span class="align-middle">{{ $t('Cancel') }}</span>
            </b-button>
          </div>
        </div>
      </div>

      <div v-if="!this.$route.params.id || onEdit">
        <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Template Name')}}</label>
        <prozess-input
            id="name"
            v-model="template.contractTemplate.name"
            field="name"
            name="name"
            :error="errorField.includes('name') ? $t('Required') : ''"
            style="padding-top: 0px"
          />
      </div>
      <div>
        <div class="row mb-0 ml-0 tw-pt-3 pt-0  mb-1">
          <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">PDF template</label>
          <input
            class="w-100"
            ref="fileExplorer"
             v-if="template.contractTemplate.file==null"
            type="file"
            accept=".pdf"
            style="padding-top: 0px;"
            @change="handleNewFileInputChange"
          />
          <p class="invalid-feedback" v-if="errorField.includes('file')">{{ $t('Required') }}</p>
          <div class="w-100 d-flex" v-if="template.contractTemplate.file!=null">
              <p style="padding: 2px 10px; margin-bottom:0px; background-color: rgba(255,255,255,0.1); border-radius: 4px; margin-right: 6px;">{{ template.contractTemplate.pdfFileName }}</p>
              <div v-if="onEdit || !this.$route.params.id" class='d-flex' style="  align-items: center;">
                <label for="reupload-file" style="margin: 0px; cursor: pointer;text-decoration: underline; width: 90px;"> {{ $t('Replace PDF') }}</label>
                <input
                  ref="fileExplorer"
                  type="file"
                  id="reupload-file"
                  accept=".pdf"
                  style="padding-top: 0px;visibility:hidden;"
                  @change="handleNewFileInputChange"
                />
              </div>
          </div>
        </div>
      </div>
      <div
        class="forms__column--selectors tw-flex"
        :class="{ 'pointer-events-none': disableSelectors }"
      >
      </div>
      <div class="m-0 mb-1 mt-1">
        <label>
          <span class="tw-flex tw-items-center tw-text-xs tw-font-medium">Form Fields</span>
        </label>
        <div v-if="!onLoadEntity" style="border-radius: 4px">
          <p class="mb-0 text-center" >{{
            template.contractTemplate.file ?
            (Object.values(wizard.pageFields).length === 0? $t('No Fields Available') : '' ) :
            $t('Upload File to Set Fields')}}</p>
          <div
            v-for="(pageNumber, i) of wizard.pages"
            :key="`pdf-page-${i}`"
            style="background-color: rgba(0,0,0,0.03); padding: 8px 10px; border-radius: 10px"
            class="mb-1">
            <div class="d-flex">
              <feather-icon icon="FileIcon" class="tw-mr-1 tw-mt-1" color="gray" />
              <p class="mb-0" style="margin-top:2px">{{ `Page ${pageNumber}` }}</p>
            </div>
              <div
                v-for="(pageField, i2) of wizard.pageFields[pageNumber]"
                :key="`page-field-${i2}`"
                style="margin-left: 20px; margin-bottom:8px;margin-top:6px; height: 40px; border-radius: 10px; align-items: center; padding:12px; background-color: rgba(0,0,0,0.03)"
                class="form-field-item d-flex"
                >
                <div class="d-flex" style="width: 30%">
                  <feather-icon icon="TypeIcon" class="tw-mr-1" style="margin-top:2px"/>
                  <p class="mb-0" >{{ pageField.acroKey }}</p>
                </div>
                <div v-if="!selectedEntity[pageField.acroKey] && onViewOnly()">
                  <p style="margin-bottom: 0px; color: white; background-color: lightgray; padding: 1px 6px; border-radius: 4px;">{{ $t("Not Mapped") }}</p>
                </div>
                <div class="d-flex" v-else-if="pageField.primary" style="width: 70%; align-items: center;">
                  <ProzessSelect
                    v-if="selectedEntity[pageField.acroKey] || !onViewOnly()"
                    v-model="selectedEntity[pageField.acroKey]"
                    :options="entityOptions"
                    :disabled="onViewOnly()"
                    style="width: 220px;"
                  />
                  <ProzessSelect
                    v-if="selectedEntity[pageField.acroKey]"
                    v-model="selectedColumns[pageField.acroKey]"
                    :options="entityColumns[selectedEntity[pageField.acroKey].key]"
                    :disabled="onViewOnly()"
                    style="width: 220px; margin-left: 8px;"
                  />
                </div>
                <div v-else>
                  <p v-if="!onViewOnly()" style="color:gray; margin-bottom: 0px; background-color: rgb(81, 173, 124); color: white; padding: 3px 6px; border-radius: 4px;">
                    <span>{{ $t("*Set mapping on Page ") }}</span>
                    <span>{{ pageField.primaryPage }}</span>
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
<!--
      <div class="row col-sm-5 col-md-4 col-12">
          <b-button id="sel-reset-button" variant="outline-primary" class="mr-1" @click="testGenerateContract">
            <span class="align-middle">{{ $t('Test Generate') }}</span>
          </b-button>
        </div> -->
    </div>

    <div
        v-if="loading || onLoadEntity"
        class="d-flex justify-content-center align-items-center"
        style="height: 100px"
      >
        <b-spinner class="align-middle" />
      </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import ProzessInput from '@core/components/ProzessInput.vue'
import API from '@axios'
import contractTemplateService from '@/services/contractTemplate'
import authUserService from '@/services/authUser'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'

export default {

  components: {
    ProzessSelect,
  },
  props: {
  },
  data() {
    return {
      showForm: false,
      fieldOptions: [],
      entities: [],
      entityOptions: [],
      entityColumns: [],
      selectedEntity: {},
      selectedColumns: {},
      template: {},
      baseTemplate: {},
      baseSelectedEntity: {},
      baseSelectedColumns: {},
      wizard: {},
      baseWizard: {},
      onEdit: false,
      errorField: [],
      onLoadEntity: false,
    }
  },
  mounted() {
  },
  async created() {
    await this.getEntities()
    if (this.$route.params.id) {
      this.setTemplateData()
    } else {
      this.template = {
        contractTemplate: {
          pdfFileName: '',
          file: null,
        },
        formFieldMap: {},
      }
    }
  },
  methods: {
    ...mapMutations({
      setResource: 'form/SET_RESOURCE',
      setUserFields: 'user/SET_USER_FIELDS',
    }),
    onViewOnly() {
      return this.$route.params.id != null && !this.onEdit
    },
    mapFields() {
      this.loading = true
      this.selectedEntity = {}
      this.selectedColumns = {}
      Object.keys(this.template.formFieldMap).forEach(ff => {
        const formField = this.template.formFieldMap[ff]
        const sEntity = this.entities[formField.metaKey]
        this.selectedEntity[ff] = {
          key: formField.metaKey,
          label: sEntity.label,
          entityKey: sEntity.key,
          relationshipId: sEntity.relationshipId,
          schema: sEntity.schema,
          type: sEntity.type,
         }
         this.selectedColumns[ff] = {
          key: formField.fieldKey,
          label: sEntity.columns[formField.fieldKey].label,
         }
      })
      this.loading = false
    },
    async setTemplateData() {
      this.loading = true
      const { id } = this.$route.params
      const { response } = await this.$async(contractTemplateService.getTemplateById(id))
      const formFieldMap = response.data.formFieldMap
      this.wizard = {
        formFieldMap: response.data.formFieldMap,
        pageFields: response.data.pageFields,
        pages: Object.keys(response.data.pageFields),
      }
      this.template = response.data.template
      this.template.contractTemplate.file = await contractTemplateService.b64toBlob(this.template.contractTemplate.rawPdf, 'application/pdf')
      this.loading = false
      this.mapFields()
    },
    resetLocalState() {
      this.entityOptions = []
    },
    async getEntities() {
      this.onLoadEntity = true
      const { response } = await this.$async(contractTemplateService.getEntities())
      this.entities = response.data
      this.entityOptions = Object.values(this.entities).map(e => ({ key: e.metaKey, label: e.label, relationshipId: e.relationshipId, schema: e.schema, type: e.type, entityKey: e.key }))
      this.entityColumns = {}
       Object.values(this.entities).forEach(en => {
        this.entityColumns[en.metaKey] = Object.values(en.columns)
       })
      this.onLoadEntity = false
      this.$forceUpdate()
    },

  async updateWithNewFile(file) {
    await contractTemplateService.getFormFields(file).then(res => {
      this.wizard = res.data
      this.wizard.pages = Object.keys(this.wizard.pageFields)
      this.loading = false
      })
      .finally(() => {
        this.loading = false
      })
  },
  async handleNewFileInputChange(e) {
    this.loading = true
    const errorIndex = this.errorField.indexOf('file')
    if (errorIndex !== -1) {
      this.errorField.splice(errorIndex, 1)
    }

    const [file] = e.target.files
    this.template.contractTemplate.file = file
    this.template.contractTemplate.pdfFileName = file.name
    await this.updateWithNewFile(file)
    this.loading = false
  },
  editContract() {
    this.baseTemplate = JSON.parse(JSON.stringify(this.template))
    this.baseSelectedEntity = JSON.parse(JSON.stringify(this.selectedEntity))
    this.baseSelectedColumns = JSON.parse(JSON.stringify(this.selectedColumns))
    this.baseWizard = JSON.parse(JSON.stringify(this.wizard))
    this.onEdit = true
  },
  saveEdit() {
   this.updateContractTemplate()
  },
  async cancelEdit() {
    this.template = JSON.parse(JSON.stringify(this.baseTemplate))
    this.template.contractTemplate.file = await contractTemplateService.b64toBlob(this.template.contractTemplate.rawPdf, 'application/pdf')
    this.selectedEntity = JSON.parse(JSON.stringify(this.baseSelectedEntity))
    this.selectedColumns = JSON.parse(JSON.stringify(this.baseSelectedColumns))
    this.wizard = JSON.parse(JSON.stringify(this.baseWizard))
    this.onEdit = false
  },
  cancelNewContract() {
    this.$router.push({ name: 'document-template' })
  },
   removeEmpty(obj) {
      return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
  },
  enrichFormFieldMap() {
    const formFieldMap = {}
    this.selectedEntity = this.removeEmpty(this.selectedEntity)
    Object.keys(this.selectedEntity)
      .forEach((key, idx) => {
        if (this.selectedEntity[key] != null) {
          const formField = {
            acroKey: key,
            metaKey: this.selectedEntity[key].key,
            relationshipId: this.selectedEntity[key].relationshipId,
            schema: this.selectedEntity[key].schema,
            type: this.selectedEntity[key].type,
            key: this.selectedEntity[key].entityKey,
            fieldKey: this.selectedColumns[key].key,
          }
          formFieldMap[key] = formField
        }
      })
      return formFieldMap
  },
  saveContractTemplate() {
      this.errorField = []
      if (this.template.contractTemplate.name === '' || this.template.contractTemplate.name == null) {
        this.errorField.push('name')
      }
      if (this.template.contractTemplate.file == null) {
        this.errorField.push('file')
      }
      if (this.errorField.length === 0) {
        this.loading = true
        const formData = new FormData()
        formData.append('name', this.template.contractTemplate.file.name)
        formData.append('file', this.template.contractTemplate.file)
        const formFieldMap = this.enrichFormFieldMap()
        const contractTemplateReq = {
          name: this.template.contractTemplate.name,
          pdfFileName: this.template.contractTemplate.pdfFileName,
          formFieldMap,
        }
        formData.append('contractTemplateReq',
        new Blob([JSON.stringify(contractTemplateReq)], {
        type: 'application/json',
        }))
        API.put('/api/crm/contract/wizard/create', formData).then(res => {
          this.$router.push({ name: 'document-template' })
        })
        .finally(() => {
          this.loading = false
        })
      }
  },
  updateContractTemplate() {
      this.loading = true
      const formData = new FormData()
      formData.append('name', this.template.contractTemplate.file.name)
      formData.append('file', this.template.contractTemplate.file)
      const contractTemplateReq = {
        id: this.template.contractTemplate.id,
        name: this.template.contractTemplate.name,
        pdfFileName: this.template.contractTemplate.pdfFileName,
        formFieldMap: this.enrichFormFieldMap(),
      }
      formData.append('contractTemplateReq',
      new Blob([JSON.stringify(contractTemplateReq)], {
      type: 'application/json',
      }))
      API.put('/api/crm/contract/wizard/update', formData).then(res => {
        this.onEdit = false
        this.baseTemplate = JSON.parse(JSON.stringify(this.template))
        this.baseSelectedEntity = JSON.parse(JSON.stringify(this.selectedEntity))
        this.baseSelectedColumns = JSON.parse(JSON.stringify(this.selectedColumns))
        this.baseWizard = JSON.parse(JSON.stringify(this.wizard))
        this.$router.push()
      })
      .finally(() => {
        this.loading = false
      })
  },
  async testGenerateContract() {
      this.loading = true
      const formData = new FormData()
      formData.append('name', this.template.contractTemplate.file.name)
      formData.append('file', this.template.contractTemplate.file)
      const contractTemplateReq = {
        name: this.template.contractTemplate.name,
        pdfFileName: this.template.contractTemplate.pdfFileName,
        formFieldMap: this.template.formFieldMap,
      }
      formData.append('contractTemplateReq',
      new Blob([JSON.stringify(contractTemplateReq)], {
      type: 'application/json',
      }))
    //  await API.put('/api/crm/contract/wizard/test-generate', formData).then(async res => {
    //     const mockContractTemplateResponse = res.data
    //     contractTemplateService.viewRawPdfFileInNewTab(mockContractTemplateResponse.contractTemplate.rawPdf)
    //   })

      this.loading = false
  },

  async getUserFields() {
      const { response } = await this.$async(authUserService.getUserFields())
      this.setUserFields(response.data)
    },

  },
}
</script>
<style lang="scss">
.dd{
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
}
</style>
