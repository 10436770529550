import API from '@axios'
import { stringify } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

export default {
    async deleteById(id) {
        return API.delete(`/api/crm/contract/template/${id}`)
    },
    getAll(query = { size: 25 }) {
        return API.get(`/api/crm/contract/getall?${stringify(query)}`)
    },
    async getFormFields(file) {
        const formData = new FormData()
        formData.append('name', 'form')
        formData.append('file', file)
        const contractTemplateReq = {
        formFieldList: [],
        }
        formData.append('contractTemplateReq',
            new Blob([JSON.stringify(contractTemplateReq)], {
            type: 'application/json',
        }))
        return API.put('/api/crm/contract/wizard/upload', formData)
    },
    getEntities() {
        return API.get('/api/crm/tree/fields/company')
    },
    async getTemplateById(id) {
        return API.get(`/api/crm/contract/template/${id}`)
    },
    async getSubsidiaries(companyUuid) {
        return API.get(`/api/crm/contract/get-subsidiaries/${companyUuid}`)
    },
    async b64toBlob(base64, contentType = 'application/octet-stream') {
        let blobbie = null
        await fetch(`data:${contentType};base64,${base64}`).then(res => { blobbie = res.blob() })
        return blobbie
    },
    viewBlobFileInNewTab(file) {
        const fileURL = window.URL.createObjectURL(file)
        const tab = window.open()
        tab.location.href = fileURL
    },
    async viewRawPdfFileInNewTab(base64) {
        const pdfFile = await this.b64toBlob(base64, 'application/pdf')
        this.viewBlobFileInNewTab(pdfFile)
    },
    async generate(req) {
        return API.put('/api/crm/contract/generate', req)
    },
    async linkDocument(docLinkReqRes) {
        console.log('linkDocument docLinkReqRes', docLinkReqRes)
        return API.post('/api/crm/contract/link-document', docLinkReqRes)
    },

}
